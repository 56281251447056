<template>
  <div role="main" class="main">
    <header class="header">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <nav class="navbar navbar-expand-md navbar-dark pl-lg-0">
              <a class="navbar-brand" href="javascript:void(0);">{{
                event_info ? event_info.decease_name : "MOURN WITH US"
              }}</a>
              <!-- <div class="navbar-list">
                <router-link
                  tag="a"
                  class="text-yellow hover-yellow mr-3 mr-md-5"
                  to="/speaker/profile"
                  >Profile</router-link
                >
              </div>
              <div class="action logout">
                <a
                  href="#"
                  class="text-yellow hover-yellow mr-3"
                  @click="logout"
                  ><i class="fas fa-sign-out-alt"></i>Logout</a
                >
              </div> -->
            </nav>
          </div>
        </div>
      </div>
    </header>
    <section
      class="common-page with-footer speaker-room-page"
      style="min-height: 560px"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-5">
            <div class="row">
              <div class="col-12">
                <div class="border-l text-left">
                  <p v-if="event_info">PARTING</p>
                </div>
              </div>
              <div class="col-md-10">
                <div class="img-box pointer" @click="goNext('upload_photo')">
                  <img
                    :src="require('@/assets/img/upload-photo.png')"
                    class="img-fluid float-left"
                  />
                  <label class="text-primary ml-2"
                    >Upload<br />
                    Your<br />
                    Photos
                  </label>
                </div>
                <div class="img-box pointer" @click="goNext('donation')">
                  <img
                    :src="require('@/assets/img/DonateWithPaypal.png')"
                    class="img-fluid float-left"
                  />
                  <label class="text-primary ml-2"
                    >Gift or Donate with<br />
                    <b>PayPal</b>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-7" v-if="event_info">
            <div class="c-scroll">
                    <div  v-for="(input,k) in imagesArray" :key="k" class="mb-3">
                    <img
                      :src="input.program_url"
                      class="img-fluid c-img-scoll"
                    />
                    </div>
                  </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="container">
      <div class="row align-items-end">
        <div class="col-md-3">
          <label class="text-primary">Type condolences message and press send</label>
          <div class="form-group">
            <!-- <textarea class="form-control" rows="3" placeholder="Please leave a personal mesagge or a memory here" v-model="message"></textarea> -->
            <VueEmoji
              ref="emoji"
              @input="onInput"
              :value="myText"
              height="200"
            />
          </div>
        </div>
        <div class="col-md-2 pl-lg-0">
          <div class="form-group">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              @click="goNext('condolence')"
            >
              Send your condolences
            </button>
          </div>
        </div>
        <div class="col-md-4 border-left pl-lg-2">
          <div
                class="img-box mt-0 form-group pointer"
                @click="goNext('voicemail')"
              >
                <img
                  src="../../assets/img/voicemail.jpg"
                  class="img-fluid float-left mr-2"
                  style="height: 98px"
                />
                <label class="text-primary px-2">
                  <b v-if="event_info && event_info.tel">Record a condolence message</b>
                </label>
              </div>
        </div>
        <div class="col-md-3 pl-lg-2" v-if="event_info && event_info.zoom_meeting_url">
          <div
            class="img-box mt-0 form-group pointer d-flex"
            @click="goNext('zoom')"
          >
            <div>
              <img
                :src="require('@/assets/img/zoom1.png')"
                class="img-fluid float-left"
                style="max-height: 100px"
              />
            </div>
            <div>
              <label class="text-primary ml-2">
                <b>Visit our wake</b>
              </label>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import { apiBaseUrl, liveBaseUrl } from "../../constants/config";
import { mapActions } from "vuex";
import VueEmoji from "emoji-vue";

export default {
  data() {
    return {
      event_info: null,
      message: "",
      myText: "",
      imagesArray:[]
    };
  },
  mounted() {
    this.event_id= this.$route.params.p_id;
    console.log("[[[[[[",this.event_id)
      getApiManager()
        .post(`${apiBaseUrl}/api/visitor/event/getEventDetail`, {
          event_id: this.event_id,
        })
      .then((response) => {
        let res = response.data;
        console.log("[[[[",res)
        if (res.status === true) {
          let detail = res.data[0];
          this.event_info = detail;
          getApiManager()
          .post(`${apiBaseUrl}/api/admin/event/getuploadImagebyEventId`, {
            event_id:  this.event_id,
          })
          .then((response) => {
              this.imagesArray=response.data.detail;
              if(this.imagesArray && this.imagesArray.length>0){
              for(var i=0;i<this.imagesArray.length;i++){
                this.imagesArray[i].program_url=`${apiBaseUrl}` + "/" + this.imagesArray[i].program_url
              }
            }
          })
          // this.event_info.program_url =
          //   `${apiBaseUrl}/` + this.event_info.program_url;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.connectSocket();
  },
  methods: {
    connectSocket() {
      var ref = this;

      this.connection = new WebSocket(liveBaseUrl);
      this.connection.onmessage = function(event) {
        //this.isLoading = false;
        let data = JSON.parse(event.data);
        if (data.type == "start_service") {
          console.log("{{{{{{startservice");
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          ref.$router.push("/speaker/whereby?x=" + randomnumber);
        }
      };

      this.connection.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second."
        );
        setTimeout(() => {
          ref.connectSocket();
        }, 1000);
      };
    },
    onInput(event) {
      this.message = event.data;
    },
    clearTextarea() {
      this.$refs.emoji.clear();
    },
    ...mapActions(["signOut"]),
    // logout() {
    //   if (self.OTSession) {
    //     self.OTSession.disconnect();
    //   }
    //   getApiManager()
    //     .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
    //       live: 0,
    //       content_type_id: this.content_type_id,
    //     })
    //     .then((response) => {
    //       localStorage.setItem("Live_id", null);
    //       this.signOut();
    //       this.$router.push("/login");
    //       window.location.reload();
    //     });
    // },
    goNext(page) {
      if (page == "condolence") {
        if (this.message == "") {
          this.$notify({
            group: "foo",
            type: "warn",
            title: "Waring",
            text: "Please input the message.",
            animation_type: "slide",
          });
          return;
        }
        getApiManager()
          .post(`${apiBaseUrl}/api/speaker/condolence/create`, {
            message: this.message,
          })
          .then((response) => {
            let res = response.data;
            if (res.status === true) {
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/speaker/condolence/" + res.condolence_id+"/"+this.event_id+"?x=" + randomnumber);
            } else {
              this.$notify({
                group: "foo",
                type: "warn",
                title: "Error",
                text: "Server Error",
                animation_type: "slide",
              });
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (page == "upload_photo") {
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/speaker/" + page+"?x=" + randomnumber);
      } else if (page == "shop") {
        if (this.event_info.flowershop_url.startsWith('http://') || this.event_info.flowershop_url.startsWith('https://')) {
          window.open(this.event_info.flowershop_url, "_blank");
        } else {
          window.open('https://' + this.event_info.flowershop_url, "_blank");
        }
      } else if (page == "donation") {
        if (this.event_info.donation_url.startsWith('http://') || this.event_info.donation_url.startsWith('https://')) {
          window.open(this.event_info.donation_url, "_blank");
        } else {
          window.open('https://' + this.event_info.donation_url, "_blank");
        }
      } else if (page == "calendar") {
        window.open(this.event_info.reminder_url, "_blank");
      } else if (page == "zoom") {
        window.open(this.event_info.zoom_meeting_url, "_blank");
      } else {
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/speaker/page/" + page+"?x=" + randomnumber);
      }
    },
  },
  components: {
    VueEmoji,
  },
  computed: {},
  watch: {},
};
</script>
<style scoped>
.c-img-scoll {
  height: 370px;
    object-fit: cover;
}
.c-scroll{
  height: calc(100vh - 400px);
  overflow: auto;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #772803;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #772803;
}
</style>